import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import './LatestSatelite.css';
import satelitePlaceholder from '../../images/satelite-img-placeholder.png'
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from "react-bootstrap";

const LatestSatelite = ({sateliteImages}: any) => {

    const [gif, setGif] = useState<any>();
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if(!modalShow){
            looper()
        }        
    }, [sateliteImages])
    
    const looper = () => {
        for (let i = 0; i <= sateliteImages.length; i++) {
        setTimeout( function timer() {
         setGif(sateliteImages[i].image_url);
         if(i === sateliteImages.length){
             looper();
         }
     }, i*400 );
   }
    }

    function MyModal(props: any){
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Satellite images
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img
                src={gif}
                alt="Satelite images"
                className="d-block w-100"
                height={500}
                width={500}
              />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
        )
    }

    // useEffect(() => {
    //     if(currentImage === 0){
    //         let interval = 1000;
    //     for(let i = 0; i < sateliteImages.length; i++){
    //         setTimeout(function () {
    //         setCurrentImage(currentImage + 1);
    //         if(i == sateliteImages.length - 1){
    //             setCurrentImage(0);
    //         }
    //         setGif(sateliteImages[i]);
    //     }, (i +1) * interval + 100);
    //     }
    //     }
        
    // }, [currentImage])

    return (
        <>
            <div id="dismissTwo" className="dismiss">
                &#10006;
            </div>
            <h2 onClick={() => setModalShow(true)}>Latest Satellite<br /> <h6>Click to enlarge</h6></h2>
            <div className="latest-satelite-body">
                {gif ? <img src={gif} alt="" /> : null}
                
            </div>
            <MyModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        animation={false}
      />
        </>
    )
}

export default LatestSatelite;