import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import './CurrentTropicalCyclone.css';
import Marquee from "react-fast-marquee";
import icon from '../../images/wd01.png'
import { STORMS } from "../../config";

const CurrentTropicalCyclone = ({showStormForecast}: any) => {

    const [data, setData] = useState<any>([])
    const [category, setCategory] = useState<any>();
    // const [counter, setCounter] = useState(0);

    const handleClick  = (id: any) => {
        showStormForecast(id)
    }

    const categoryFunction = (speed: any) => {
        let now = (+(speed) * 1.15);
        if(now < 39) {
            return 'D'
        } else if (now >= 39 && now <= 73) {
            return 'S'
        } else if (now >= 74 && now <= 95) {
            return '1';
        } else if (now >= 96 && now <= 110) {
            return '2';
        } else if (now >= 111 && now <= 129) {
            return '3';
        } else if (now >= 130 && now <= 156) {
            return '4';
        } else if (now >= 157) {
            return '5';
        }
      }

    function getDirection(angle: any) {
            var directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
            var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
            return directions[index];
     }

    useEffect(()=> {
        axios.get(STORMS)
        .then((res) => {
        setData(res.data.data.result)
        });
    }, [])

    return (
        <>
            <div id="dismissThree" className="dismiss">
                                &#10006;
                            </div>
                            <h2  className='heading'>Current Tropical Cyclones</h2>
                            <div className="current-tropical-cyclones-body mCustomScrollbar">                              
                               {data.map((item: any) => {                                   
                                   return (
                                    <div className="tropical-cyclone-box" key={item.id}>
                                    <div className="cyclone-top-part" onClick={() => handleClick(item.id)}>
                                        <div className={`wind-direction hurricane-mode-${categoryFunction(item.storms_data[0]?.wind_speed)}`}>
                                            <p className="count-circle">{categoryFunction(item.storms_data[0]?.wind_speed)}</p>
                                        </div>
                                        <div className="cyclone-info">
                                            <h2>{item.name}</h2>
                                            <p>{item.area}</p>
                                        </div>
                                    </div>
                                    <div className="cyclone-bottom-part">
                                        <div className="wind-measure">
                                            <span>{(item.storms_data[0]?.wind_speed * 1.15).toFixed(2) || 'N.A '}mph / {(item.storms_data[0]?.pressure) || 'N.A '}mb</span>
                                            <span>/</span>
                                            <span>Moving {getDirection(item.storms_data[0].compass)}</span>
                                        </div>
                                        {/* <div className="cyclone-marquee-wrap">
                                            <h2 className="cyclone-marque-warning">Warning :</h2>
                                            <div className="marque-box">
                                                <Marquee gradient={false}>Lorem Ipsum is simply dummy text</Marquee>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                   )
                               })}
                            </div>
                            <div className="current-tropical-cyclones-footer">
                                <span>Last Updated</span>
                                <span>9 Sec ago</span>
                            </div>
        </>
    )
}

export default CurrentTropicalCyclone;