import React, {useEffect, useState} from "react";
import { GoogleMap, useJsApiLoader, Marker, Polygon, Polyline, InfoBox, Circle } from '@react-google-maps/api';
import { isTemplateExpression, JsxElement } from "typescript";
import './WeatherMapForWarning.css'


const WeatherMapForWarning = ({polygon}: any) => {

    //This function returns Polygon for Storm Warnings.
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const zoom = 4;
  const [map, setMap] = useState(null);

  useEffect(() => {
    setPageLoaded(false);
    setPageLoaded(true);  
  }, [polygon])

  useEffect(() => {
    setPageLoaded(true);    
  }, [])
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAQTfJrhaj_qcdEEbK2wVn5VFM49Eicjl4"
  })

  const options = {
    fillColor: "lightblue",
    fillOpacity: 0.5,
    strokeColor: "tomato",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
  }
  
  const onLoad = (polygon: any)=>(map: any)=>{

        const bounds = new window.google.maps.LatLngBounds({lat: polygon[0].lat, lng: polygon[0].lng});
        map.fitBounds(bounds);
        setMap(map)
    
  }

  const onUnmount = React.useCallback(function callback(map: any) {
    polygon = [];
  }, [])

  const mapStyles = {
    height: "100%",
    width: "100%",
    zIndex: 2
  }

    return isLoaded && pageLoaded ? (
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={polygon[0]}
        zoom={6}
        // onLoad={onLoad(polygon)}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
          <Polygon
              // onLoad={onPolygonLoad}
              options={options}
              paths={polygon}
            />
      </GoogleMap>
  ) : null
}

export default WeatherMapForWarning;