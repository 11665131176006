import React, { FC } from "react";
import './Footer.css';

const Footer: FC = () => {
    return (
        <>
            <div className="col-sm-9">
                    <div className="footer-wrap">
                        {/* <h5>Next Official Update due</h5> */}
                        <div className="official-update-box-wrap">
                            <div className="official-update-box">
                                {/* <span>BOM</span>
                                <em>4 months</em> */}
                            </div>
                            <div className="official-update-box">
                                {/* <span>FMS</span>
                                <em>4 months</em> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="footer-wrap footer-mail">
                        <a href="mailto:contact@force-13.com">contact@force-13.com</a>
                    </div>
                </div>
        </>
    )
}

export default Footer;