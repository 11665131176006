import React, {FC, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import './HomePage.css';
import axios from 'axios';
import WeatherMap from '../../components/weatherMap/WeatherMap';
import WeatherMapForCyclone from '../../components/WeatherMapForCyclone/WeatherMapForCyclone';
import WeatherMapForWarning from '../../components/WeatherMapForWarning/WeatherMapForWarning';
import HeaderComponent from '../../components/HeaderComponent/HeaderComponent';
import HurricaneSeason from '../../components/HurricaneSeason/HurricaneSeason';
import LatestWarnings from '../../components/LatestWarnings/LatestWarnings';
import LatestSatelite from '../../components/LatestSatelite/LatestSatelite';
import CurrentTropicalCyclone from '../../components/CurrentTropialCyclone/CurrentTropicalCyclone';
import Footer from '../../components/Footer/Footer';

import { STORMS, WARNINGS } from '../../config';
import { Polygon } from '@react-google-maps/api';
import { getOverlayDirection } from 'react-bootstrap/esm/helpers';

interface Polygons {
    polygon: [
      {
        lat: number;
        lng: number;
      }
    ]
  }

const HomePage: FC = () => {

    const [data, setData] = useState<any>([]);
    const [latitude, setLatitude] = useState<number>(0);
    const [longitude, setLongitude] = useState<number>(0);
    const [forecastForMap, setForecastForMap] = useState<any>();
    const [stormForecast, setStormForecast] = useState<any>([]);
    const [stormName, setStormName] = useState<String>('');
    const [stormArea, setStormArea] = useState<String>('');
    const [speedAndDirection, setSpedAndDirection] = useState<any>({})
    const [stormStatus, setStormStatus] = useState<string>('');
    const [forecastData, setForecastData] = useState<any>();
    const [marker, setMarker] = useState<any>({});
    const [warningForecast, setWarningForecast] = useState<any>();
    const [warningEvent, setWarningEvent] = useState<any>();
    const [noForecast, setNoForecast] = useState<boolean>(false)
    const [polygon, setPolygon] = useState<any[]>();
    const [hasPolygon, setHasPolygon] = useState<boolean>(false);
    const [sateliteImages, setSateliteImages] = useState<any[]>();
    const [showLatestSatelite, setShowLatestSatelite] = useState<boolean>(false);
    const [hasMapData, setHasMapData] = useState<boolean>(false);

    function getDirection(angle: any) {
        var directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
        var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
        return directions[index];
 }

    const showStormForecast = (id: number) => {
        setShowLatestSatelite(false)
        setStormForecast([])
        setHasMapData(false)
        setTimeout(() => {
            for(let i = 0; i < data.length; i++) {
            if(data[i].id === id) {
                let forecast = data[i].storms_forcast.sort((a: any, b: any) => {
                    let firstDate: any = new Date(a.dateTime)
                    let second: any = new Date(b.dateTime)
                    return (firstDate - second)
                })
                setHasPolygon(false);
                setNoForecast(false)   
                setStormName(data[i].name);
                setStormArea(data[i].area);
                let obj = {
                    speed: data[i].storms_data[0].wind_speed,
                    direction: getDirection(data[i].storms_data[0].compass),
                    pressure: data[i].storms_data[0].pressure,
                }
                setSpedAndDirection(obj)
                setStormStatus(data[i].storms_data[0].storm_status);
                setShowLatestSatelite(true)
                setSateliteImages(data[i].satellite_images)
                setLatitude(data[i].storms_data[0]?.lat)
                setLongitude(data[i].storms_data[0]?.lng)
                setStormForecast(forecast);
                if(data[i].storms_forcast.length){
                    const temp = [];
                    for (let j = 0; j < forecast.length; j++) {
                    let obj = {
                        lat: +(forecast[j].lat),
                        lng: +(forecast[j].lng),
                        dateTime: forecast[j].dateTime,
                        wind_speed: (+forecast[j].wind_speed),
                        baseTime: data[i].storms_data[0].storm_date_time,
                        name: data[i].name
                    }
                    temp.push(obj)
                }
                setForecastForMap(temp);
                setHasMapData(true)
                setMarker(null)
                } else {
                    setForecastForMap(null)
                    setMarker({
                        lat: +(data[i].storms_data[0].lat),
                        lng: +(data[i].storms_data[0].lng),
                })
                        setHasMapData(true)                    
                }
                
            }
        }
        }, 150)
        return;
    }

    const getData = () => {
         axios.get(STORMS)
        .then((res) => {
            setHasMapData(false);
            setPolygon([])
            setShowLatestSatelite(true);
            setSateliteImages(res.data.data.result[0].satellite_images)
            setData(res.data.data.result)
            setForecastData(res.data.data.result[0].storms_data)
            setStormName(res.data.data.result[0].name)
            setStormArea(res.data.data.result[0].area)
            setStormStatus(res.data.data.result[0].storms_data[0].storm_status);            
                let obj = {
                    speed: res.data.data.result[0].storms_data[0].wind_speed,
                    direction: getDirection(res.data.data.result[0].storms_data[0].compass),
                    pressure: res.data.data.result[0].storms_data[0].pressure,
                }
                setSpedAndDirection(obj)

            let forecast = res.data.data.result[0].storms_forcast.sort((a: any, b: any) => {
                    let firstDate: any = new Date(a.dateTime)
                    let second: any = new Date(b.dateTime)
                    return (firstDate - second)
            })

            if (res.data.data.result[0].storms_forcast.length){
                const temp = [];
                for (let j = 0; j < forecast.length; j++) {
                    let obj = {
                        lat: +(forecast[j].lat),
                        lng: +(forecast[j].lng),
                        dateTime: forecast[j].dateTime,
                        wind_speed: forecast[j].wind_speed,
                        baseTime: res.data.data.result[0].storms_data[0].storm_date_time,
                        name: res.data.data.result[0].name
                        // dateTime: forecast[j].dateTime,
                        // wind_speed: (+forecast[j].wind_speed),
                        // baseTime: data[i].storms_data[0].storm_date_time,
                    }
                    temp.push(obj)
                }
                setForecastForMap(temp);
                setMarker(null)
            } else {
                setForecastForMap(null);
                setMarker({
                    lat: +(res.data.data.result[0].storms_data[0].lat),
                    lng: +(res.data.data.result[0].storms_data[0].lng)
                });
            }
                setStormForecast(forecast);
                setHasMapData(true)
                setHasPolygon(false);
        }).catch((err) => {
            console.log(err)
        })
    }

    const warnings = (id: any) => {
        let polygon: any = [];
        axios.get(WARNINGS)
        .then((res) => {
            setHasPolygon(false)
            setHasMapData(false)
            setPolygon([])
            setForecastForMap(null);
            setMarker(null);
            let warningData = res.data.data.result.filter((item: any) => item.id == id);
            setWarningForecast(warningData);
            setNoForecast(true);
            setWarningEvent(warningData[0].event);
            let temp = warningData[0].polygon? warningData[0].polygon.split(' ') : [];
            if(temp.length){
            for(let i = 0; i < temp.length; i++) {
            let data = {
                lat: +(temp[i].split(',')[0]),
                lng: +(temp[i].split(',')[1])
            }
            polygon.push(data)
            setHasMapData(true)
        }
        setPolygon(polygon);
        setHasPolygon(true);
        }
        })
        .catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        getData();
    }, [])

    // useEffect(()=> {
    //     if(hasPolygon === false){
    //         getData()
    //     }
    // }, [hasPolygon])

    return (  
        <div className="main-wrap">
            <Helmet>
                <title>Force-13</title>
            </Helmet>

            <div className="top-part-wrap">
                <div className='row'>
                    <div className="col-sm-6 for-small-device-100">
                        <div className="left-part-wrap">
                            <div className='logo-time-box'>
                                <HeaderComponent />
                            </div>        

                                <div className='map-wrap'>
                                {/* {hasPolygon && polygon ? (
                                    <WeatherMap polygon={polygon} warningEvent={warningEvent} />
                                ) : (forecastForMap && hasMapData ? (
                                        <WeatherMap forecast={forecastForMap} />
                                   ) : (
                                            <>
                                            <h1>Map is not available for this storm...</h1>
                                            </>
                                   )
                                        )
                                }; */}
                                {hasPolygon && polygon && polygon.length ? (
                                    <>
                                    <WeatherMapForWarning polygon={polygon} />
                                    </>
                                ) : (forecastForMap && forecastForMap.length ? (
                                    <>
                                    <WeatherMapForCyclone forecast={forecastForMap} />
                                    </>
                                ): ( marker ? (
                                    <>
                                    <WeatherMapForCyclone marker={marker} />                                    
                                    </>
                                ) : <h1>Area coordinates not available</h1>                            
                                ))}
                                </div>                                              
                           
                            <div className='hurricane-season-wrap'>
                                {noForecast ? (
                                    <HurricaneSeason warningData={warningForecast} stormName={stormName} />
                                ) : (
                                    <HurricaneSeason stormForecast={stormForecast}
                                    stormName={stormName}
                                    stormStatus={stormStatus}
                                    speedAndDirection={speedAndDirection}
                                    forecastData={forecastData}
                                    stormArea={stormArea} />
                                )}
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 for-small-device-none">
                        <div className="middle-part-wrap" id="sidebar">
                            <div className='latest-warning-wrap'>
                                <LatestWarnings warnings={warnings} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 for-small-device-none">
                        <div className="right-part-wrap">
                            <div className="latest-satelite-wrap" id="sidebarTwo">
                                {sateliteImages && showLatestSatelite ? <LatestSatelite sateliteImages={sateliteImages} /> : null}                                
                            </div>
                            <div className="current-tropical-cyclones-wrap" id="sidebarThree">
                                <CurrentTropicalCyclone showStormForecast={showStormForecast} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
               <div className="row">
                 <Footer />
               </div>
            </div>
        </div>
    )
}

export default HomePage;