import React, { useEffect, useState } from "react";
import './HurricaneSeason.css'; 
// import moment from "moment";

const HurricaneSeason = ({stormForecast, stormName, stormArea, speedAndDirection, stormStatus, forecastData, warningData}: any) => {
    
    const [list, setList] = useState<any>([]);
    const [name, setName] = useState<String>('')
    const [type, setType] = useState<String>('N.A');
    const [baseTime, setBaseTime] = useState<any>();
    const [area, setArea] = useState<String>('')
    const [speed, setSpeed] = useState<any>();
    const [direction, setDirection] = useState<any>();
    const [pressure, setPressure] = useState<any>();
    const [severity, setSeverity] = useState<String>('Default');

    useEffect(() => {
        if(warningData) {
            if(warningData[0].severity){
                setSeverity(warningData[0].severity);
            } else {
                setSeverity('Default');
            }
            setList([])
        }
        if(forecastData && forecastData.length){
            setBaseTime(new Date(forecastData[0].storm_date_time).getTime());
            setList(stormForecast);
            setName(stormName);
            setArea(stormArea);
            setSpeed(speedAndDirection.speed);
            setPressure(speedAndDirection.pressure);
            setDirection(speedAndDirection.direction);
        }
    }, [stormForecast, stormName, stormArea, stormStatus, forecastData, warningData, speedAndDirection])

    useEffect(()=> {
        if(stormStatus === 'HU') {
            setType('Hurricane');
        } else if (stormStatus === 'TS'){
            setType('Tropical Storm')
        } else if(stormStatus === 'TD') {
            setType('Tropical Depression')
        } else if (stormStatus === 'SS'){
            setType('Subtropical Storm')
        } else if(stormStatus === 'SD'){
            setType('Subtropical Depression')
        } else {
            setType('N.A')
        }
    }, [stormForecast])

    return (
        <>
        {warningData ? (            
                <div className={`hurricane-name-${severity}`}>
                    <p className="titles">Urgency: </p>
                    <p className="titles-details">{(warningData[0].urgency)}</p>
                    <p className="titles">Severity:</p>
                    <p className="titles-details">{(warningData[0].severity)}</p>
                    <p className="titles">Certainity: </p>
                    <p className="titles-details">{(warningData[0].certainty)}</p>
                </div>
        ) : (
            <div className="hurricane-name">
                <h2>{type}</h2>
                <h3>{name}</h3>
                <p>{area}</p> <br />
                <strong className="label">Speed: {(speed * 1.15).toFixed(2)} mph</strong> &nbsp; &nbsp;
                <strong className="label">Pressure: {pressure} mb</strong> <br />
                {/* <h2>{(speed * 1.15).toFixed(2)} mph / {pressure} mb</h2> */}
                <strong className="label">Direction: {direction}</strong>
            </div>
        )}

            <div className="hurricane-count">
                {list && list.length ? (
                    <>
                    {list.map((item: any) => {
                        if(+(item.wind_speed) * 1.15 < 39){
                            return (
                                <div className="hurricane-count-box hurricane-mode-D" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">D</div>
                                </div>
                            )
                        }else if((+(item.wind_speed) * 1.15 >= 39) && (+(item.wind_speed) * 1.15 <= 73)) {
                            return (                            
                            <div className="hurricane-count-box hurricane-mode-S" key={item.id}>
                                <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                <div className="count-circle">S</div>
                            </div>
                        )
                        } else if ((+(item.wind_speed) * 1.15 >= 74) && (+(item.wind_speed) * 1.15 <= 95)) {
                            return (                            
                                <div className="hurricane-count-box hurricane-mode-01" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">1</div>
                                </div>
                            )
                        } else if ((+(item.wind_speed) * 1.15 >= 96) && (+(item.wind_speed) * 1.15 <= 110) ) {
                            return (                            
                                <div className="hurricane-count-box hurricane-mode-02" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">2</div>
                                </div>
                            )
                        } else if ((+(item.wind_speed) * 1.15 >= 111) && (+(item.wind_speed) * 1.15 <= 129) ) {
                            return (                            
                                <div className="hurricane-count-box hurricane-mode-03" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">3</div>
                                </div>
                            )
                        } else if ((+(item.wind_speed) * 1.15 >= 130) && (+(item.wind_speed) * 1.15 <= 156) ) {
                            return (                            
                                <div className="hurricane-count-box hurricane-mode-04" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">4</div>
                                </div>
                            )
                        } else if ((+(item.wind_speed) * 1.15 >= 157)) {
                            return (                            
                                <div className="hurricane-count-box hurricane-mode-05" key={item.id}>
                                    <h2>In {Math.abs(Math.round(((baseTime - (new Date(item.dateTime)).getTime())/1000) /(60 * 60)))} hours</h2>
                                    <p>{+((item.wind_speed) * (1.15)).toFixed(2)}mph</p>
                                    <div className="count-circle">5</div>
                                </div>
                            )
                        }
                        })}
                    </>
                            ) : ( warningData ? (
                                <div className="warning-box">                                    
                                        <p className="warning-box-description">{warningData[0].description}</p>                                                                       
                                </div>
                                ) : null
                            )}
                    </div>
                </>
    )
}

export default HurricaneSeason;