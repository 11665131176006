import React, {useEffect, useState} from "react";
import { GoogleMap, useJsApiLoader, Polyline, Circle, Marker, InfoWindow, Data } from '@react-google-maps/api';
import './WeatherMapForCyclone.css'
import { isPartiallyEmittedExpression } from "typescript";
import moment from "moment";

const WeatherMapForCyclone = ({forecast, marker}: any) => {

  const [hasMarker, setHasMarker] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [baseTime, setBaseTime] = useState<any>();
  const [speed, setSpeed] = useState<any>();
  const [time, setTime] = useState<any>();
  const zoom = 4;
  const [map, setMap] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState<any>(false);
  const [category, setCategory] = useState<any>(null);
  let styles: any = [];

  useEffect(() => {
    if(marker) {
      setHasMarker(true);
    } else {
      setHasMarker(false);
    }
    setPageLoaded(true);
  }, [forecast, marker])

  const categoryFunction = (speed: any) => {
    let now = (speed * 1.15);
    if(now < 39) {
      setCategory('D')      
    } else if (now >= 39 && now <= 73) {
      setCategory('S')      
    } else if (now >= 74 && now <= 95) {
      setCategory('1');      
    } else if (now >= 96 && now <= 110) {
      setCategory('2');      
    } else if (now >= 111 && now <= 129) {
      setCategory('3');     
    } else if (now >= 130 && now <= 156) {
      setCategory('4');      
    } else if (now >= 157) {
      setCategory('5');      
    }
  }

  const circleFunction = (speed: any) => {
    let now = (speed * 1.15);
    if(now < 39) {
      styles.push(D)
    } else if (now >= 39 && now <= 73) {
      styles.push(S)  
    } else if (now >= 74 && now <= 95) {
      styles.push(One)    
    } else if (now >= 96 && now <= 110) {
      styles.push(Two)    
    } else if (now >= 111 && now <= 129) {
      styles.push(Three)
    } else if (now >= 130 && now <= 156) {
      styles.push(Four)
    } else if (now >= 157) {
      styles.push(Five)
    }
  }


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAQTfJrhaj_qcdEEbK2wVn5VFM49Eicjl4"
  })
  
  //Circle-----------------------------
  const defaultCircle = {
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
  }


const S = {
    ...defaultCircle,
    strokeColor: '#4bd54f',
    fillColor: '#15f51c',
}

const D = {
    ...defaultCircle,
    strokeColor: '#08b3f3',
    fillColor: '#2bbef4',
}

const One = {
    ...defaultCircle,
    strokeColor: '#40400d',
    fillColor: '#8a8a07',
}

const Two = {
    ...defaultCircle,
    strokeColor: '#df9605',
    fillColor: '#fdac06'
}

const Three = {
    ...defaultCircle,
    strokeColor: '#bc4f07',
    fillColor: '#ff6e0a',
}

const Four = {
    ...defaultCircle,
    strokeColor: '#d81d01',
    fillColor: '#ff2300',
}

const Five = {
    ...defaultCircle,
    strokeColor: '#b50672',
    fillColor: '#e6058f',
};
  const farOptions = {
    ...defaultCircle,
    fillOpacity: 0.5,
    strokeColor: 'rgb(43,190,244)',
    fillColor: 'rgb(43,190,244)',
  }

  //Circle End -----------------------
  
  const dashedSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  let polyLineOptions = {
  strokeColor: 'black',
  strokeOpacity: 0,
  strokeWeight: 0.5,
  // fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
  }


  const onLoad = ({forecast, marker}: any)=>(map: any)=>{

    if (forecast) {
          const bounds = new window.google.maps.LatLngBounds({lat: +forecast[0].lat, lng: forecast[0].lng});
          map.fitBounds(bounds);
          setMap(map)
    } else if (marker) {
      const bounds = new window.google.maps.LatLngBounds(marker);
          map.fitBounds(bounds);
          setMap(map)
    }
  }

  const onUnmount = React.useCallback(function callback(map: any) {    
    forecast = [];
  }, [])

  const mapStyles = {
    height: "100%",
    width: "100%",
    zIndex: 2
  }

    return isLoaded && pageLoaded ? (
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={forecast ? {lat: forecast[0].lat, lng: forecast[0].lng} : marker}
        zoom={6}
        // onLoad={onLoad(forecast || marker)}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {forecast && forecast.length ? (
          forecast.map((item: any, id: number) => {
            circleFunction(item.wind_speed)
            return (
              <>
                <Circle center={{lat: item.lat, lng: item.lng}}
                radius={45000}
                options={styles[id]}
                onClick={() => {
                  setSelectedCenter(item);
                  setSpeed(item.wind_speed);
                  setBaseTime(new Date(item.baseTime).getTime());
                  setTime(item.dateTime)
                  categoryFunction(item.wind_speed)
               }}
                /> 
                {selectedCenter && (
                    <InfoWindow
                        onCloseClick={() => {
                          setSelectedCenter(false);
                        }}
                        position={{
                          lat: selectedCenter.lat,
                          lng: selectedCenter.lng
                        }}
                    >
                        <>                      
                        <h4 className="infoboxHeading">{item.name} - Category {category} Hurricane</h4>
                        <hr style={{backgroundColor: 'black', margin: '5px'}} />
                        <h4 className="texts"><strong>Local: </strong>{moment(new Date().getTime()).format('MM-DD-YYYY, hh:mm:ss A')}</h4>
                        <h4 className="texts">(In {Math.abs(Math.round(((baseTime - (new Date(time)).getTime())/1000) /(60 * 60)))} hours)</h4>
                        <h4 className="texts"><strong>UTC: </strong>{time}</h4>
                        {/* <br /> */}
                        <h5 className="textsWithGap"><strong>Winds: </strong>{+((speed) * (1.15)).toFixed(2)}mph</h5>
                        {/* <br /> */}
                        <h5 className="textsWithGap"><strong>Source: </strong>NHC</h5>
                        </>
                    </InfoWindow>
                  )}
                <Polyline
                  onLoad={onLoad}
                  path={forecast}
                  options={{...polyLineOptions, icons: [
                    {
                    icon: {path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                      strokeOpacity: 1,
                      scale: 3,
                    },
                    offset: '0',
                    repeat: '25px'
                    },                    
                  ]}}
                />
                  </>
            )
                })
        ) : ( marker && hasMarker ? (
          <Marker
          // icon={'https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/arrow_forward_ios/default/48px.svg'}
          position={marker}
          >
          </Marker>
        ) : null          
        )}          
      </GoogleMap>
  ) : ( <>
        <h2>Map is loading...</h2>
      </>)
}

export default WeatherMapForCyclone;