import React, { useEffect, useState } from "react";
import './LatestWarnings.css';
import axios from "axios";
import { WARNINGS } from "../../config";

const LatestWarnings = ({warnings} : any) => {

    const [data, setData] = useState<any>([])

    useEffect(() => {
        getWarningData()
    }, [])

    const getWarningData = () => {
        axios.get(WARNINGS)
        .then((res) => {
        setData(res.data.data.result);
        })
        .catch((err) => {
            console.log(err);
        })
    }
    return (
        <div className="mainDiv">
        <div id="dismiss" className="dismiss">
                                &#10006;
                            </div>
                            <h2 className="title">Latest Warnings</h2>
                            <div className="latest-warning-body mCustomScrollbar">
                                {data.map((item: any) => {
                                    if((item.severity === 'Minor')){
                                        return (
                                            <div key={item.id} className="latest-warning-box warning-level-00">
                                    <div className="warning-info" onClick={() => warnings(item.id)}>
                                        <h2>{item.country_name}</h2>
                                        <h3>{item.event}</h3>
                                        <p>{item.date_time}</p>
                                    </div>
                                    <div className="country-flag">
                                        <img src={`https://www.geonames.org/flags/x/${item.country_code}.gif`} alt="" />
                                    </div>
                                    </div>
                                        )
                                    }
                                    
                                    if(item.severity === 'Severe') {
                                        return (
                                    //severity
                                    <div key={item.id} className="latest-warning-box warning-level-01">
                                    <div className="warning-info" onClick={() => warnings(item.id)}>
                                        <h2>{item.country_name}</h2>
                                        <h3>{item.event}</h3>
                                        <p>{item.date_time}</p>
                                    </div>
                                    <div className="country-flag">
                                        <img src={`https://www.geonames.org/flags/x/${item.country_code}.gif`} alt="" />
                                    </div>
                                    </div>
                                )
                                    } else if(item.severity === 'Moderate') {
                                        return (
                                    //severity
                                    <div key={item.id} className="latest-warning-box warning-level-02">
                                    <div className="warning-info" onClick={() => warnings(item.id)}>
                                        <h2>{item.country_name}</h2>
                                        <h3>{item.event}</h3>
                                        <p>{item.date_time}</p>
                                    </div>
                                    <div className="country-flag">
                                        <img src={`https://www.geonames.org/flags/x/${item.country_code}.gif`} alt="" />
                                    </div>
                                </div>
                                )
                                    } else if(item.severity === 'Extreme') {
                                        return (
                                    //severity
                                    <div key={item.id} className="latest-warning-box warning-level-03">
                                    <div className="warning-info" onClick={() => warnings(item.id)}>
                                        <h2>{item.country_name}</h2>
                                        <h3>{item.event}</h3>
                                        <p>{item.date_time}</p>
                                    </div>
                                    <div className="country-flag">
                                        <img src={`https://www.geonames.org/flags/x/${item.country_code}.gif`} alt="" />
                                    </div>
                                </div>
                                )
                                } else {
                                    return (
                                        <div key={item.id} className="latest-warning-box warning-level-03">
                                    <div className="warning-info" onClick={() => warnings(item.id)}>
                                        <h2>{item.country_name}</h2>
                                        <h3>{item.event}</h3>
                                        <p>{item.date_time}</p>
                                    </div>
                                    <div className="country-flag">
                                        <img src={`https://www.geonames.org/flags/x/${item.country_code}.gif`} alt="" />
                                    </div>
                                </div>
                                    )
                                } 
                                })}
                            </div>
        </div>
    )
}

export default LatestWarnings;