import React, { FC, useEffect, useState } from "react";
import { timezoneList } from "../../static/TimezoneList";
import Form from 'react-bootstrap/Form'
import logo from '../../images/logo.png'
import moment from "moment";
import { tz } from "moment-timezone";
import momentTZ from 'moment-timezone';
import TimezoneSelect from 'react-timezone-select'

import './HeaderComponent.css'

const HeaderComponent: FC = () => {

    const [timezone, setTimezone] = useState<any>({});
    const [abbr, setAbbr] = useState<any>(timezoneList[0].abbr);

    return (
                    <>
                        <div className="logo-wrap">
                                    <a href="index.html">
                                        <img src={logo} alt="" />
                                        <h1>Monitoring cyclone Activity Worldwide</h1>
                                    </a>
                                </div>
                                <div className="date-time-wrap">
                                        <div className="date-time-row">
                                            <p><span>{moment(new Date().toLocaleString("en-US", { timeZone: "Africa/Abidjan" })).format('MM-DD-YYYY, hh:mm:ss A')}</span></p>
                                            {/* <p><span>2022-06-09</span> <span>01:49:47</span></p> */}
                                            <em>GMT</em>
                                        </div>
                                    <div className="date-time-row">
                                    <p><span>{moment(new Date().getTime()).format('MM-DD-YYYY, hh:mm:ss A')}</span></p>
                                        {/* <p><span>2022-06-08</span> <span>18:47:31</span></p> */}                                        
                                        <em>{tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')}</em>
                                    </div>
                                    <div className="date-time-row">
                                        {/* <p><span>2022-06-09</span> <span>10:50:08</span></p> */}
                                        {/* **Working line** <p><span>{moment(new Date().getTime()).format('MM-DD-YYYY, hh:mm:ss A')}</span></p> */}
                                        {/* <em>{Intl.DateTimeFormat().resolvedOptions().timeZone}</em> */}
                                        {/* <em>{moment.locale()}</em> */}
                                        <p>
                                            <span>
                                                <Form.Select onChange={(e) => {
                                                    setAbbr(e.target.value)
                                                }}>
                                                    {timezoneList.map((item: any) => {
                                                        return (
                                                                <option value={`${item.abbr}`}>{moment(new Date().toLocaleString("en-US", { timeZone: item.utc[0]})).format('MM-DD-YYYY, hh:mm:ss A')} - {item.utc[0]}</option>
                                                        )
                                                    })}
                                                    {/* <option value={'Etc/GMT+0'}>{moment(new Date().toLocaleString("en-US", { timeZone: "Africa/Abidjan" })).format('MM-DD-YYYY, hh:mm:ss A')} - Africa/Abidjan</option>
                                                    <option value={'Asia/Calcutta'}>{moment(new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })).format('MM-DD-YYYY, hh:mm:ss A')} - Asia/Calcutta</option> */}
                                                </Form.Select>
                                                
                                            </span>
                                        </p>
                                        {/* <em>{tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z')}</em> */}
                                        <em>{abbr}</em>
                                    </div>
                                </div>
                                </>
        // </div>
    )
}

export default HeaderComponent;